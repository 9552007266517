import '../styles/index.scss';
import * as faceapi from 'face-api.js';
import CameraRenderer from './CameraRenderer';
import ViewManager from './ViewManager';

Promise.all([
  faceapi.nets.tinyFaceDetector.loadFromUri('/public/models'),
  faceapi.nets.faceLandmark68Net.loadFromUri('/public//models'),
  faceapi.nets.faceRecognitionNet.loadFromUri('/public/models'),
]).then(() => {
  const viewManager = new ViewManager();
  viewManager.showWelcomeScreen();
  // viewManager.hide(viewManager.welcomeScreen);
  // viewManager.show(viewManager.gameEndScreen);
  // viewManager.showGameScreen();
});
