import { SpriteSheet, Sprite } from '@createjs/easeljs';

class Ship {
  constructor(x, y, engine) {
    this.x = x;
    this.y = y;
    this.engine = engine;
    this.name = 'ship';
    this.vy = 0;
    this.vx = 0;
    this.speed = 1.5;
    this.friction = 0.7;
    this.alive = true;
    this.spriteSheet = new SpriteSheet({
      images: [this.engine.assets.shipImg],
      frames: {
        width: 64,
        height: 70,
      },
      animations: {
        stand: 0,
        turnLeft: [1, 2],
        turnRight: [3, 4],
        acc: [5, 8, 'acc', 1],
        nonacc: [7, 8, 'stand'],
      },
    });
  }

  draw() {
    const animation = new Sprite(this.spriteSheet);
    animation.x = this.x;
    animation.y = this.y;
    animation.gotoAndStop('stand');
    this.graphics = animation;
    this.engine.containers.game.addChild(animation);
    this.engine.stage.update();
  }

  goUp() {
    this.vy -= this.speed;
  }

  goLeft() {
    this.vx -= this.speed;
  }

  goRight() {
    this.vx += this.speed;
  }

  goDown() {
    this.vy += this.speed;
  }

  frictionX() {
    this.vx *= this.friction;
  }

  frictionY() {
    this.vy *= this.friction;
  }

  update() {
    this.graphics.x += this.vx;
    this.graphics.y += this.vy;
    if (this.graphics.x < 5) {
      this.graphics.x = 5;
      this.vx = 0;
    } else if (
      this.graphics.x >
      this.engine.width - this.graphics.spriteSheet._frameWidth - 5
    ) {
      this.graphics.x = this.engine.width - this.graphics.spriteSheet._frameWidth - 5;
      this.vx = 0;
    }
    if (this.graphics.y < 5) {
      this.graphics.y = 5;
      this.vy = 0;
    } else if (
      this.graphics.y >
      this.engine.height - this.graphics.spriteSheet._frameHeight - 5
    ) {
      this.graphics.y = this.engine.height - this.graphics.spriteSheet._frameHeight - 5;
      this.vy = 0;
    }
  }
}

export default Ship;
