import CameraRenderer from './CameraRenderer';
import MoveAnalyzer from './MoveAnalyzer';
import Engine from './game/Engine';

class ViewManager {
  constructor() {
    this.loadingScreen = document.getElementById('loading-screen');
    this.welcomeScreen = document.getElementById('welcome-screen');
    this.cameraCalibratingScreen = document.getElementById('camera-calibration-screen');
    this.calibrationTester = document.getElementById('calibration-tester');
    this.gamePlayScreen = document.getElementById('game-play-screen');
    this.gameEndScreen = document.getElementById('game-end-screen');
    this.velocity = 0;
    this.isShooting = false;
    this.translateX = null;

    this.calibrationData = {
      calibrationNextDisabled: true,
      rafId: null,
    };
    this.cameraRenderer = null;
    this.gameEngine = null;
    this.attachVideoEvents();
  }

  attachVideoEvents() {
    const video = document.getElementById('gameplay-video');
    const buttons = document
      .querySelector('.gameplay-rates-buttons')
      .querySelectorAll('.rate-button');
    [...buttons].forEach((button) => {
      button.addEventListener('click', (e) => {
        video.playbackRate = +e.target.dataset.rate;
        video.play();
      });
    });
    document.getElementById('play-again-button').addEventListener('click', () => {
      this.gameEngine.restart();
    });
  }

  show(element) {
    element.classList.remove('hidden');
  }

  hide(element) {
    element.classList.add('hidden');
  }

  setGameEndResult(result) {
    document.getElementById('game-end-screen-result').innerHTML = result;
  }

  showWelcomeScreen() {
    this.hide(this.loadingScreen);
    this.show(this.welcomeScreen);
    this.welcomeScreen.querySelector('.pure-button').addEventListener('click', () => {
      this.showCalibrationScreen();
    });
  }

  showGameScreen() {
    this.hide(document.getElementById('calibration-tester'));
    this.hide(document.querySelector('.camera-chooser'));
    this.hide(document.querySelector('.camera-next-button'));
    this.hide(document.querySelector('.camera-calibration-info'));
    document.getElementById('camera-calibration-screen').classList.add('game-mode');
    document.getElementById('camera-container').classList.add('camera-in-game');
    this.show(this.gamePlayScreen);
    this.gameEngine = new Engine(this);
    this.gameEngine.setCameraRenderer(this.cameraRenderer);
    this.gameEngine.init();

    window.xGame = this.gameEngine;
  }

  showCalibrationScreen() {
    this.hide(this.welcomeScreen);
    this.show(this.cameraCalibratingScreen);

    const analyzer = new MoveAnalyzer();

    this.cameraRenderer = new CameraRenderer();

    this.translateX =
      ~~(this.cameraRenderer.video.offsetWidth - this.calibrationTester.offsetWidth) / 2;
    this.calibrationTester.style.transform = `translateX(${this.translateX}px)`;

    this.cameraRenderer.viewManager = this;
    this.cameraRenderer.callback = analyzer.analyze;

    this.cameraRenderer
      .getCameraList()
      .then((cameras) => this.cameraRenderer.prepareCamerasDropdown(cameras));

    this.runCalibrate();
  }

  calibrateTester(moveData) {
    this.velocity = moveData.velocity;
    this.isShooting = moveData.isShooting;

    if (this.calibrationData.calibrationNextDisabled) {
      this.calibrationData.calibrationNextDisabled = false;
      const el = document.getElementById('calibration-next');
      el.disabled = false;
      el.addEventListener('click', () => {
        this.calibrationData.rafId && cancelAnimationFrame(this.calibrationData.rafId);
        this.cameraRenderer.type = 'game';
        this.showGameScreen();
      });
    }
  }

  runCalibrate() {
    this.translateX = this.translateX - this.velocity * 10;
    if (this.translateX < 0) {
      this.translateX = 0;
    }
    if (this.translateX > 660) {
      this.translateX = 660;
    }

    this.calibrationTester.style.transform = `translateX(${this.translateX}px)`;

    this.calibrationTester.style.background = this.isShooting ? 'red' : 'aqua';

    this.calibrationData.rafId = requestAnimationFrame(() => this.runCalibrate());
  }
}

export default ViewManager;
