import * as faceapi from 'face-api.js';

class CameraRenderer {
  constructor() {
    this.type = 'calibrate';
    this.cameraId = undefined;
    this.video = document.getElementById('video');
    this.interval = null;
    this.callback = null;
    this.viewManager = null;
    this.stream = null;
  }

  getCameraList() {
    return new Promise((resolve) => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        console.log(devices);
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
        resolve(videoDevices);
      });
    });
  }

  prepareCamerasDropdown(devices) {
    const select = document.getElementById('camera-chooser');
    devices.forEach((item) => {
      const opt = document.createElement('option');
      opt.appendChild(
        document.createTextNode(
          `${item.label ? item.label : 'Camera'} (${item.deviceId.slice(0, 5)})`,
        ),
      );
      opt.value = item.deviceId;
      select.appendChild(opt);
    });
    select.addEventListener('change', (event) => {
      this.cameraId = event.target.value;

      this.startVideo();
    });
    this.startVideo();
  }

  startVideo() {
    let constraints = { video: {} };
    if (this.cameraId) {
      constraints.video.deviceId = this.cameraId;
    }
    navigator.mediaDevices.getUserMedia(constraints).then(
      (stream) => {
        this.stream = stream;
        this.video.srcObject = stream;
      },
      (err) => console.error(err),
    );

    this.video.addEventListener('play', () => {
      const canvases = this.video.parentNode.getElementsByTagName('canvas');
      if (canvases.length) {
        [...canvases].forEach((item) => {
          this.video.parentNode.removeChild(item);
        });
      }
      if (this.interval) {
        window.clearInterval(this.interval);
      }

      const canvas = faceapi.createCanvasFromMedia(this.video);
      this.video.parentNode.append(canvas);

      // document.body.append(canvas);
      const displaySize = { width: this.video.width, height: this.video.height };
      faceapi.matchDimensions(canvas, displaySize);

      this.interval = setInterval(async () => {
        const detections = await faceapi
          .detectSingleFace(
            this.video,
            new faceapi.TinyFaceDetectorOptions({ inputSize: 160 }),
          )
          .withFaceLandmarks();
        if (!detections) {
          return false;
        }
        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        const result = this.callback(resizedDetections.landmarks.positions);

        if (this.type === 'calibrate') {
          this.viewManager.calibrateTester(result);
        } else {
          this.viewManager.gameEngine.faceMoveHandler(result);
        }

        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
      }, 100);
    });
  }
}

export default CameraRenderer;
