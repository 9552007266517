class MoveAnalyzer {

  constructor() {
  }

  analyze(positions) {

    let velocity;
    let isShooting;


    const faceWidth = Math.abs(positions[16].x - positions[0].x);
    const leftPart = Math.abs(positions[36].x - positions[0].x) / faceWidth;
    const rightPart = Math.abs(positions[16].x - positions[45].x) / faceWidth;

    const faceHeight = Math.abs(positions[8].y - positions[27].y);
    const mouthHeight = Math.abs(positions[57].y - positions[51].y) / faceHeight;

    isShooting = mouthHeight > 0.15;

    if (Math.abs(leftPart - rightPart) > 0.05) {
      velocity = leftPart - rightPart;
    } else {
      velocity = 0;
    }

    return({
      velocity,
      isShooting
    });

  }

}

export default MoveAnalyzer;
