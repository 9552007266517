import { layers, HrandomChoice, Hrandom } from './helpers';
import { Graphics, Shape } from '@createjs/easeljs';

class Star {
  constructor(engine) {
    this.engine = engine;
  }

  initStars(_count) {
    let n;
    let sum = 0;
    let l;
    let layer;
    this.stars = [];
    this.starsShapes = [];

    for (n = 0; n < layers.length; n++) {
      l = layers[n];
      l.min = sum;
      l.max = sum + l.percent;
      sum = l.max;
    }
    for (n = 0; n < _count; n++) {
      layer = this.randomLayer();
      this.stars.push({
        layer: layer,
        color: HrandomChoice(layer.colors),
        speed: Hrandom(layer.speed.min, layer.speed.max),
        size: Hrandom(layer.size.min, layer.size.max),
        x: Hrandom(0, this.engine.width),
        y: Hrandom(0, this.engine.height),
      });
    }
  }

  randomLayer() {
    let i;
    let n = Hrandom(1, 100);
    for (i = 0; i < layers.length; i++) {
      if (n <= layers[i].max) return layers[i];
    }
  }

  draw() {
    let star;
    let n;
    const l = this.stars.length;
    for (n = 0; n < l; n++) {
      star = this.stars[n];
      const g = new Graphics();
      g.beginFill(star.color);
      g.drawCircle(0, 0, star.size);
      const s = new Shape(g);
      s.x = star.x;
      s.y = star.y;
      s.snapToPixel = true;
      s.cache(-star.size, -star.size, star.size * 2, star.size * 2);
      this.starsShapes.push(s);
      this.engine.containers.stars.addChild(s);
    }
    this.engine.stage.update();
  }

  update() {
    const l = this.starsShapes.length;
    for (let i = 0; i < l; i++) {
      this.starsShapes[i].y += this.stars[i].speed / 4;
      if (this.starsShapes[i].y > this.engine.height) {
        this.starsShapes[i].y = 0;
        this.starsShapes[i].x = Hrandom(0, this.engine.width);
      }
    }
    this.engine.stage && this.engine.stage.update();
  }
}

export default Star;
