import { Graphics, Shape, Text } from '@createjs/easeljs';

class Hud {
  constructor(engine) {
    this.engine = engine;
    this.draw();
  }

  draw() {
    const g = new Graphics();
    g.beginFill('#FFFFFF');
    g.rect(0, 0, this.engine.width, 10);
    const s = new Shape(g);
    s.x = 0;
    s.y = 0;
    s.alpha = 0.4;
    this.graphics = s;
    this.engine.containers.hud.addChild(s);

    const counter = new Text(this.engine.killedCount, '32px Arial', '#ffffff');
    counter.x = this.engine.width - counter.getMeasuredWidth() - 10;
    counter.y = this.engine.height - 10;
    counter.alpha = 0.4;
    counter.textBaseline = 'alphabetic';
    this.counter = counter;
    this.engine.containers.hud.addChild(counter);
  }

  update() {
    const g = new Graphics();
    g.beginFill('#FFFFFF');
    g.rect(0, 0, this.engine.width * (this.engine.life / 100), 10);
    this.graphics.graphics = g;

    this.counter.text = this.engine.killedCount;
    this.counter.x = this.engine.width - this.counter.getMeasuredWidth() - 10;
  }
}

export default Hud;
