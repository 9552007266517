class CameraRecorder {
  record(stream) {
    console.log('record', stream);
    this.shouldStop = false;
    this.stopped = false;

    const options = { mimeType: 'video/webm' };
    const recordedChunks = [];

    if (!MediaRecorder) {
      return false;
    }

    this.mediaRecorder = new MediaRecorder(stream);
    console.log(this.mediaRecorder);

    this.mediaRecorder.ondataavailable = function (e) {
      console.log('data available');
      recordedChunks.push(e.data);
    };

    this.mediaRecorder.onstop = function (e) {
      console.log('onstop fired');
      const blob = new Blob(recordedChunks, { type: 'video/ogv; codecs=opus' });
      console.log(blob);

      const video = document.getElementById('gameplay-video');
      video.src = window.URL.createObjectURL(blob);
    };

    this.mediaRecorder.onwarning = function (e) {
      console.log('onwarning fired', e);
    };

    this.mediaRecorder.onerror = function (e) {
      console.log('onerror fired', e);
    };

    this.mediaRecorder.start();
  }

  stop() {
    this.mediaRecorder.stop();
  }
}

export default CameraRecorder;
