import { SpriteSheet, Sprite } from '@createjs/easeljs';

class Enemy {
  constructor(x, y, engine) {
    this.x = x;
    this.y = y;
    this.engine = engine;
    this.name = 'enemy';
    this.spriteSheet = new SpriteSheet({
      images: [this.engine.assets.enemyImg],
      frames: {
        width: 32,
        height: 46,
      },
      animations: {
        run: {
          frames: [0, 1, 2, 3, 2, 1],
          next: true,
          frequency: 5,
        },
      },
    });
    this.explosionSpriteSheet = new SpriteSheet({
      images: [this.engine.assets.explosionImg],
      frames: {
        width: 64,
        height: 64,
      },
      animations: {
        run: [0, 15],
      },
    });
  }

  draw() {
    const animation = new Sprite(this.spriteSheet);
    animation.x = this.x;
    animation.y = this.y;
    animation.scale = 1.3;
    this.graphics = animation;
    this.engine.containers.game && this.engine.containers.game.addChild(animation);
    animation.gotoAndStop('run');
    this.engine.stage.update();
  }

  update(_i) {
    this.graphics.y += 3;
    if (this.graphics.y > this.engine.height) {
      this.engine.containers.game && this.engine.containers.game.removeChild(this.graphics);
      this.engine.enemies.splice(_i, 1);
      this.engine.life -= 25; //TODO PK remove this
      this.engine.audio.failed.play();
      if (this.engine.life <= 0) {
        this.engine.finishGame();
      }
      this.engine.hud.update();
    }
  }

  kill(_i) {
    const animation = new Sprite(this.explosionSpriteSheet);
    animation.x = this.graphics.x - 24;
    animation.y = this.graphics.y - 24;
    animation.scale = 1.5;
    this.engine.containers.game.addChild(animation);
    animation.gotoAndPlay('run');

    animation.on('animationend', () => {
      this.engine.containers.game.removeChild(animation);
    });

    this.engine.containers.game.removeChild(this.graphics);
    this.engine.enemies.splice(_i, 1);
  }
}

export default Enemy;
