import { Stage, Container, Ticker, Text } from '@createjs/easeljs';
import { Howl, Howler } from 'howler';
import Star from './Star';
import Ship from './Ship';
import Bullet from './Bullet';
import Enemy from './Enemy';
import Hud from './Hud';
import { Hrandom } from './helpers';
import CameraRecorder from '../CameraRecorder';

const KEYCODE_DOWN = 40;
const KEYCODE_LEFT = 37;
const KEYCODE_RIGHT = 39;
const KEYCODE_SPACE = 32;
const KEYCODE_UP = 38;

class Engine {
  constructor(viewManager) {
    this.viewManager = viewManager;
  }

  init() {
    this.keys = {
      upK: false,
      dnK: false,
      ltK: false,
      rtK: false,
      shoot: false,
    };

    this.width = document.body.clientWidth;
    this.height = document.body.clientHeight;

    this.canvas = document.getElementById('game-canvas');
    this.canvas.width = this.width;
    this.canvas.height = this.height;

    this.stage = new Stage(this.canvas);
    this.containers = {};

    this.containers.loading = new Container();
    const loadingMsg = new Text('Ładowanie ...', 'bold 18px Arial', '#FFF');
    this.containers.loading.addChild(loadingMsg);
    loadingMsg.x = ~~(this.width - 113) / 2;
    loadingMsg.y = ~~(this.height - 18) / 2;
    this.stage.addChild(this.containers.loading);
    this.stage.update();

    this.bullets = [];
    this.bulletsLimit = 0;

    this.enemies = [];
    this.enemiesLimit = 0;

    this.assets = {};
    this.assetsCount = 0;
    this.audio = {};

    this.life = 100;
    this.killedCount = 0;

    this.assets.shipImg = new Image();
    this.assets.shipImg.src = '/public/assets/image/ship.png';
    this.assets.shipImg.onload = () => {
      this.assetsLoaded();
    };

    this.assets.ammoImg = new Image();
    this.assets.ammoImg.src = '/public/assets/image/ammo.png';
    this.assets.ammoImg.onload = () => {
      this.assetsLoaded();
    };

    this.assets.enemyImg = new Image();
    this.assets.enemyImg.src = '/public/assets/image/enemy.png';
    this.assets.enemyImg.onload = () => {
      this.assetsLoaded();
    };

    this.assets.explosionImg = new Image();
    this.assets.explosionImg.src = '/public/assets/image/explosion.png';
    this.assets.explosionImg.onload = () => {
      this.assetsLoaded();
    };

    this.audio.bullet = new Howl({
      src: ['/public/assets/audio/bullet.mp3'],
      volume: 0.3,
      onload: () => {
        this.assetsLoaded();
      },
    });

    this.audio.explosion = new Howl({
      src: ['/public/assets/audio/explosion.mp3'],
      volume: 0.5,
      onload: () => {
        this.assetsLoaded();
      },
    });

    this.audio.failed = new Howl({
      src: ['/public/assets/audio/failed.mp3'],
      volume: 0.5,
      onload: () => {
        this.assetsLoaded();
      },
    });

    this.audio.bgMusic = new Howl({
      src: ['/public/assets/audio/bg-music.mp3'],
      volume: 0.6,
      loop: true,
      onload: () => {
        this.assetsLoaded();
      },
    });

    this.cameraRecorder = new CameraRecorder();
  }

  restart() {
    this.viewManager.hide(this.viewManager.gameEndScreen);
    this.init();
  }

  setCameraRenderer(renderer) {
    this.cameraRenderer = renderer;
  }

  assetsLoaded() {
    this.assetsCount++;
    if (this.assetsCount === 8) {
      this.startGame();
    }
  }

  startGame() {
    this.stage.removeChild(this.containers.loading);

    this.cameraRecorder.record(this.cameraRenderer.stream);

    this.containers.stars = new Container();
    this.stage.addChild(this.containers.stars);

    this.star = new Star(this);
    this.star.initStars(~~(this.height / 2));
    this.star.draw();

    this.containers.game = new Container();
    this.stage.addChild(this.containers.game);

    this.containers.hud = new Container();
    this.stage.addChild(this.containers.hud);
    this.hud = new Hud(this);

    this.ship = new Ship(this.width / 2, this.height - 90, this);
    this.ship.draw();

    this.isPaused = false;

    this.audio.bgMusic.play();

    if (!this.nextInit) {
      document.onkeydown = (e) => {
        this.onKeyDown(e);
      };
      document.onkeyup = (e) => {
        this.onKeyUp(e);
      };
      Ticker.on('tick', () => {
        this.tick();
      });
    }
  }

  fireBullet() {
    const bullet = new Bullet(this.ship.graphics.x, this.ship.graphics.y, this);
    bullet.draw();
    bullet.graphics.gotoAndPlay('run');
    this.bullets.push(bullet);
    this.audio.bullet.play();
  }

  createEnemy() {
    const enemy = new Enemy(Hrandom(50, this.width - 50), -50, this);
    enemy.draw();
    enemy.graphics.gotoAndPlay('run');
    this.enemies.push(enemy);
  }

  faceMoveHandler(data) {
    if (data.velocity > 0) {
      this.keys.ltK = true;
      this.ship && this.ship.graphics.gotoAndStop('turnLeft');
    } else if (data.velocity < 0) {
      this.keys.rtK = true;
      this.ship && this.ship.graphics.gotoAndStop('turnRight');
    } else {
      this.keys.ltK = false;
      this.keys.rtK = false;
      this.ship && this.ship.graphics.gotoAndStop('stand');
    }

    this.keys.shoot = data.isShooting;
  }

  onKeyDown(e) {
    switch (e.which) {
      case KEYCODE_UP:
        this.keys.upK = true;
        this.ship.graphics.gotoAndPlay('acc');
        break;
      case KEYCODE_LEFT:
        this.keys.ltK = true;
        this.ship.graphics.gotoAndStop('turnLeft');
        break;
      case KEYCODE_RIGHT:
        this.keys.rtK = true;
        this.ship.graphics.gotoAndStop('turnRight');
        break;
      case KEYCODE_DOWN:
        this.keys.dnK = true;
        break;
      case KEYCODE_SPACE:
        this.keys.shoot = true;
        break;
    }
  }

  onKeyUp(e) {
    switch (e.which) {
      case KEYCODE_UP:
        this.keys.upK = false;
        this.ship.graphics.gotoAndPlay('nonacc');
        break;
      case KEYCODE_LEFT:
        this.keys.ltK = false;
        this.ship.graphics.gotoAndStop('stand');
        break;
      case KEYCODE_RIGHT:
        this.keys.rtK = false;
        this.ship.graphics.gotoAndStop('stand');
        break;
      case KEYCODE_DOWN:
        this.keys.dnK = false;
        break;
      case KEYCODE_SPACE:
        this.keys.shoot = false;
        break;
    }
  }

  tick() {
    if (this.isPaused) {
      return false;
    }

    if (this.ship.alive && this.keys.upK) {
      this.ship.goUp();
    } else if (this.ship.alive && this.keys.dnK) {
      this.ship.goDown();
    } else {
      this.ship.frictionY();
    }

    if (this.ship.alive && this.keys.ltK) {
      this.ship.goLeft();
    } else if (this.ship.alive && this.keys.rtK) {
      this.ship.goRight();
    } else {
      this.ship.frictionX();
    }
    this.ship.update();

    if (this.bulletsLimit <= 0) {
      if (this.ship.alive && this.keys.shoot) {
        this.bulletsLimit = 2;
        this.fireBullet();
      }
    } else {
      this.bulletsLimit--;
    }

    for (let i = 0, l = this.bullets.length; i < l; i++) {
      if (this.bullets[i]) {
        this.bullets[i].update(i);
      }
    }

    if (this.enemiesLimit <= 0) {
      if (this.ship.alive) {
        this.enemiesLimit = 100 - this.killedCount * 2.5;
        if (this.enemiesLimit < 20) {
          this.enemiesLimit = 20;
        }
        this.createEnemy();
      }
    } else {
      this.enemiesLimit--;
    }

    for (let i = 0, l = this.enemies.length; i < l; i++) {
      if (this.enemies[i]) {
        this.enemies[i].update(i);
      }
    }

    for (let i = 0, l = this.bullets.length; i < l; i++) {
      if (this.bullets[i]) {
        const targets = this.containers.game.getObjectsUnderPoint(
          this.bullets[i].graphics.x,
          this.bullets[i].graphics.y,
        );
        if (targets.length > 0) {
          for (let j = 0, k = this.enemies.length; j < k; j++) {
            if (this.enemies[j] && this.enemies[j].graphics.id === targets[0].id) {
              this.enemies[j].kill(j);
              this.bullets[i].kill(i);
              this.audio.explosion.play();
              this.killedCount++;
              this.hud.update();
            }
          }
        }
      }
    }

    this.star.update();
  }

  finishGame() {
    this.cameraRecorder.stop();
    this.audio.bgMusic.stop();

    this.stage.removeAllEventListeners();
    this.stage.removeAllChildren();
    this.isPaused = true;
    this.nextInit = true;

    this.viewManager.setGameEndResult(this.killedCount);
    this.viewManager.show(this.viewManager.gameEndScreen);
  }
}

export default Engine;
